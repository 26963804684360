<template>
  <div style="background: #F9FBFB;height:100%">
    <van-nav-bar title="提现" left-arrow @click-left="onClickLeft" />
    <div class="tips">
      为了您的账户安全，需要向你奢粉绑定的手机发送验证码，请注意短信查收。
    </div>
    <div class="content">
      <div class="contentBorder">
        <div class="contentCode">
          <div class="phone">*******0772</div>
          <div class="codeTxt">获取验证码</div>
        </div>
        <div class="van-hairline--top" style="margin-top:32px"></div>
        <div class="fieldInput">
          <van-field v-model="code" placeholder="请输入验证码" />
        </div>
        <div class="btn">提交</div>
        <div class="tipsCode">收不到验证码？</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: ""
    };
  },
  methods: {
    onClickLeft() {}
  }
};
</script>

<style lang="scss" scoped>
.tips {
  margin: 40px 44px;
  font-size: 29px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  line-height: 47px;
}
.content {
  margin: 0 21px;
  height: 568px;
  background: #ffffff;
  border-radius: 14px;
  .contentBorder {
    margin: 0 44px;
    .contentCode {
      padding-top: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .phone {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        line-height: 47px;
      }
      .codeTxt {
        width: 267px;
        height: 69px;
        line-height: 69px;
        border: 1px solid #fbc034;
        border-radius: 35px;
        text-align: center;
        font-size: 31px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #fbc034;
      }
    }
    .fieldInput {
      margin-top: 44px;
    }
    .btn {
      height: 97px;
      background: #c7c7c7;
      border-radius: 49px;
      margin-top: 70px;
      text-align: center;
      font-size: 38px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      line-height: 97px;
    }
    .tipsCode {
      font-size: 25px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 47px;
      text-align: center;
      margin-top: 45px;
    }
  }
}
</style>
